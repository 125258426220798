import polyglotI18nProvider from "ra-i18n-polyglot";
import { CustomRoutes, memoryStore } from "react-admin";
import { Route } from "react-router";

import Polyglot from "node-polyglot";
import authProvider from "./authProvider";
import { API_URL } from "./config";
import Configuration from "./configuration/Configuration";
import { MyDashboard } from "./dashboard";
import HttpDataProvider from "./dataProvider/HttpDataProvider";
import chineseMessages from "./i18n/ch";
import { Layout, Login } from "./layout";
import { lightTheme } from "./layout/themes";

import Admins from "./pages/Admin";
import LoginLogs from "./pages/LoginLog";
import Users from "./pages/User";
import UserByGroup from "./pages/UserByGroup";
import UserWallets from "./pages/user/UserWallet";

import GoodsCategory from "./pages/goods/GoodsCategory";
import Goods from "./pages/goods/Goods";
import GoodsOrder from "./pages/goods/GoodsOrder";

import Docs from "./pages/content/Doc";
import WebPages from "./pages/content/WebPage";
import DocCategories from "./pages/content/DocCategory";

import Settings from "./pages/setting/setting";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DevSettings from "./pages/devSetting/devSettings";
import SiteResource from "./pages/content/SiteResource";
import SiteNotification from "./pages/content/SiteNotification";
import UserLevelSetting from "./pages/user/UserLevelSetting";
import UserHead from "./pages/content/UserHead";
import Coin from "./pages/coin/Coin";
import CoinProtocol from "./pages/coin/CoinProtocol";
import Protocol from "./pages/coin/Protocol";
import { createMenuContext } from "./hook/MenuCentext";
import { menuItems } from "./MenuData";
import { MyResource } from "./components/core/MyResource";
import SysModule from "./pages/sys/SysModule";
import SysRole from "./pages/sys/SysRole";
import { ConfirmDialogProvider } from "./hook/ConfirmContext";
import Assistant from "./pages/chat/Assistant";
import UserType from "./pages/user/UserType";
import AssistantCategory from "./pages/chat/AssistantCategory";
import MyAdmin from "./components/core/MyAdmin";
import UserTypeOpRecord from "./pages/user/UserTypeOpRecord";
import AppVersion from "./pages/app/AppVersion";
import AppsPage from "./pages/app/AppsPage";
import ApiKeyInfo from "./pages/api/ApiKeyInfo";
import VoiceActor from "./pages/voice/VoiceActor";
import assistantType from "./pages/api/AssistantType";
import VoiceActorType from "./pages/api/VoiceActorType";
import ChatUserDayUsage from "./pages/user/ChatUserDayUsage";

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    return chineseMessages;
  },
  "ch",
  {
    onMissingKey: (
      key: string,
      options: Polyglot.InterpolationOptions,
      locale: string
    ) => key,
  }
);

// function NewComponent() {
//   let notify = useNotify();
//
//   console.log(notify);
//   debugger;
//
//   window.addEventListener("error", (error) => {
//     console.log("捕获到异常：", error.error);
//     notify(error.error);
//   });
//   window.addEventListener("unhandledrejection", (error) => {
//     console.log("捕获到异常：", error.reason.message);
//     notify(error.reason.message);
//   });
//
//   return <div></div>;
// }

const App = () => {
  const { Provider: MenuProvider } = createMenuContext(menuItems);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ConfirmDialogProvider>
        <MenuProvider>
          <MyAdmin
            title="管理后台"
            dataProvider={HttpDataProvider(API_URL)}
            authProvider={authProvider}
            dashboard={MyDashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            store={memoryStore()}
            theme={lightTheme}
          >
            <CustomRoutes>
              <Route path="/configuration" element={<Configuration />} />
            </CustomRoutes>
            <MyResource name="admins" {...Admins} />
            <MyResource name="users" {...Users} />
            <MyResource name="userTypes" {...UserType} />
            <MyResource name="userByGroup" {...UserByGroup} />
            <MyResource name="userTypeOpRecords" {...UserTypeOpRecord} />
            <MyResource name="chatUserDayUsage" {...ChatUserDayUsage} />

            <MyResource name="userWallets" {...UserWallets} />
            <MyResource name="loginLogs" {...LoginLogs} />
            <MyResource name="docs" {...Docs} />
            <MyResource name="userHead" {...UserHead} />
            <MyResource name="webPages" {...WebPages} />

            <MyResource name="docCategories" {...DocCategories} />
            <MyResource name="settings" {...Settings} />
            <MyResource name="devSettings" {...DevSettings} />
            <MyResource name="sysModules" {...SysModule} />
            <MyResource name="sysRoles" {...SysRole} />

            <MyResource name="goodsCategories" {...GoodsCategory} />
            <MyResource name="goods" {...Goods} />
            <MyResource name="goodsOrders" {...GoodsOrder} />
            <MyResource name="siteResource" {...SiteResource} />
            <MyResource name="siteNotification" {...SiteNotification} />
            <MyResource name="userLevelSetting" {...UserLevelSetting} />
            <MyResource name="coins" {...Coin} />
            <MyResource name="coinProtocols" {...CoinProtocol} />
            <MyResource name="protocols" {...Protocol} />

            <MyResource name="assistants" {...Assistant} />
            <MyResource name="voiceActors" {...VoiceActor} />
            <MyResource name="voiceActorTypes" {...VoiceActorType} />
            <MyResource name="assistantCategories" {...AssistantCategory} />
            <MyResource name="assistantTypes" {...assistantType} />
            <MyResource name="apiKeyInfos" {...ApiKeyInfo} />

            <MyResource name="apps" {...AppsPage} />
            <MyResource name="appVersions" {...AppVersion} />
          </MyAdmin>
        </MenuProvider>
      </ConfirmDialogProvider>
    </LocalizationProvider>
  );
};

export default App;
